import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import pxToRem from '@web/utils/pxToRem';
import theme from '@web/styles/theme';
import IconCircleContainer from '../Icon/IconCircleContainer';
import ImpactiveLinkButton from '@web/components/ImpactiveLinkButton';
import ImpactiveButton from '@web/components/ImpactiveButton';

const Container = styled.div`
  padding: ${pxToRem(24)};
  box-shadow: 7px 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${pxToRem(12)};

  ${({ variant }) => {
    if (variant === 'default')
      return css`
        border: 1px solid ${theme.colors.teal};
        background: ${theme.colors.white};
      `;
    if (variant === 'blue')
      return css`
        border: 1px solid ${theme.colors.teal};
        background: linear-gradient(
          110.94deg,
          rgba(108, 203, 218, 0.2) 9.31%,
          rgba(108, 203, 218, 0.006) 102.42%
        );
      `;
  }};
`;

const WideFlexContainer = styled.div`
  display: flex;
  gap: 18px;
`;

const RightContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(24)};

  margin-bottom: ${pxToRem(8)};
`;

const Text = styled.div`
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(20)};
`;

const CallToActionCard = ({
  title,
  text,
  icon,
  buttonVariant,
  buttonText,
  variant,
  ctaHref,
  onClick,
}) => {
  //Impactive Buttons needs a prop to be set to true, if you want to switch types
  const buttonVariantObject = buttonVariant ? { [buttonVariant]: true } : {};

  return (
    <Container variant={variant}>
      <WideFlexContainer>
        <IconCircleContainer diameter="52px">{icon}</IconCircleContainer>
        <RightContainer>
          <div>
            <Title>{title}</Title>
            {text && <Text>{text}</Text>}
          </div>
          {ctaHref ? (
            <ImpactiveLinkButton minWidth="150px" href={ctaHref} {...buttonVariantObject}>
              {buttonText}
            </ImpactiveLinkButton>
          ) : (
            <ImpactiveButton minWidth="150px" onClick={onClick} {...buttonVariantObject}>
              {buttonText}
            </ImpactiveButton>
          )}
        </RightContainer>
      </WideFlexContainer>
    </Container>
  );
};

CallToActionCard.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string,
  ctaHref: PropTypes.string,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.node,
  title: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

CallToActionCard.defaultProps = {
  buttonProps: null,
  text: null,
  variant: 'default',
};

export default CallToActionCard;
