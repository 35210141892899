import theme from '@web/styles/theme';
import pxToRem from '@web/utils/pxToRem';
import styled, { css } from 'styled-components';

const GeneralCardWrapper = styled.div`
  padding: ${pxToRem(24)} ${pxToRem(20)};
  background-color: ${({ color }) => color || theme.colors.white};
  border-radius: 6px;
  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 1px solid ${borderColor};
    `};
`;

export default GeneralCardWrapper;
