import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import ImpactiveButton from '@web/components/ImpactiveButton';
import { message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import StripeLogo from './StripeLogo';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 150px;
`;

const PaymentForm = ({ onSuccess }) => {
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = React.useState(false);
  const isSubmitButtonDisabled = !stripe || loading;

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    stripe
      .confirmSetup({
        confirmParams: {
          // This isn't really used in most cases, but let's have a valid app link just in case
          return_url: 'https://app.impactive.io/',
        },
        elements,
        redirect: 'if_required',
      })
      .then(result => {
        // Handle Stripe error when confirming the payment method
        if (result.error) {
          message.error(result.error.message);
          setLoading(false);
        } else {
          onSuccess(result);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <ButtonsContainer>
        <ButtonWrapper>
          <StripeLogo />
        </ButtonWrapper>
        <ButtonWrapper>
          <ImpactiveButton htmlType="submit" size="full-width" disabled={isSubmitButtonDisabled}>
            Subscribe
          </ImpactiveButton>
        </ButtonWrapper>
      </ButtonsContainer>
    </form>
  );
};

PaymentForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default PaymentForm;
