import React from 'react';
import PropTypes from 'prop-types';

import completedImage from '@assets/images/AdminAuth/completed.png';
import stars from '@assets/images/AdminAuth/stars.png';
import building from '@assets/images/AdminAuth/building.png';

import styled from 'styled-components';
import GeneralCardWrapper from '@web/components/common/Cards/GeneralCardWrapper';
import theme from '@web/styles/theme';
import { Header, PrimaryText } from '../../TextComponents';
import dayjs from 'dayjs';
import CallToActionCard from '@web/components/common/Cards/CallToActionCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
`;

const StyledCard = styled(GeneralCardWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px;
`;

const StarsIcon = () => <img src={stars} alt="start-faced emoji" />;
const BuildingIcon = () => <img src={building} alt="building emoji" />;

const PaymentCompleted = ({ campaign }) => {
  const currentDate = new Date();
  const nextBillDate = dayjs(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
  ).format('MMMM DD, YYYY');

  return (
    <Container>
      <StyledCard borderColor={theme.colors.teal40}>
        <img src={completedImage} alt="payment completed" height={100} />
        <Header>Sign Up Completed!</Header>
        <PrimaryText>
          Your next monthly payment will be automatically charged on - {nextBillDate}
        </PrimaryText>
      </StyledCard>
      <CallToActionCard
        variant="blue"
        buttonText="Begin"
        buttonVariant="coral"
        onClick={() => (window.location.href = `/admin/${campaign.slug}`)}
        title="Ready to Get Started?"
        text="Begin customizing your campaign, inviting users, and more."
        icon={<StarsIcon />}
      />
      <CallToActionCard
        variant="default"
        buttonText="Read the guide"
        buttonVariant="secondary"
        ctaHref="https://support.impactive.io/hc/en-us/categories/4412585003671-Getting-Started"
        title="How It Works"
        text="Our step-by-step guide to preparing your campaign for volunteers."
        icon={<BuildingIcon />}
      />
    </Container>
  );
};

PaymentCompleted.propTypes = {
  campaign: PropTypes.object,
};

export default PaymentCompleted;
