import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ImpactiveButton from '@web/components/ImpactiveButton';

const StyledHref = styled.a`
  :link {
    text-decoration: none;
  }
  display: flex;
  margin: auto 0;
`;

export const ImpactiveLinkButton = ({ href, ...rest }) => {
  return (
    <StyledHref target="_blank" rel="noopener noreferrer" href={href}>
      <ImpactiveButton {...rest} />
    </StyledHref>
  );
};

ImpactiveLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  href: PropTypes.string.isRequired,
};

export default ImpactiveLinkButton;
