import theme from '@web/styles/theme';
import styled from 'styled-components';

const IconCircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  padding: 10px;
  border-radius: 50%;
  width: ${({ diameter }) => diameter || '40px'};
  height: ${({ diameter }) => diameter || '40px'};
  background-color: ${({ color }) => color || theme.colors.white};

  > img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export default IconCircleContainer;
