import theme from '@web/styles/theme';
import pxToRem from '@web/utils/pxToRem';
import styled from 'styled-components';

export const GrayText = styled.div`
  color: ${theme.colors.blue60};
  > a {
    text-decoration: underline;
    color: ${theme.colors.blue60};
    :hover {
      color: ${theme.colors.coral};
    }
  }
`;

export const Header = styled.h1`
  color: ${theme.colors.black};
  font-weight: 600;
  font-size: 43px;
  line-height: 52px;

  margin-bottom: ${pxToRem(16)};
`;

export const BoldLabel = styled.div`
  color: ${theme.colors.black};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const PrimaryText = styled.div`
  color: ${theme.colors.black};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
