import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 8px 0;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const Subtext = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
`;

const PaymentLine = ({ title, text, subtext }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <VerticalContainer>
        <Text>{text}</Text>
        <Subtext>{subtext}</Subtext>
      </VerticalContainer>
    </Container>
  );
};

PaymentLine.propTypes = {
  subtext: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PaymentLine;
